import React from 'react'
import { images } from 'config/images'

export const CustomSoftDevComProps = {
  ppcbannerProps: {
    heading: (
      <>
        Custom software developer trusted by{' '}
        <span className="highlight-head">Fortune 1000</span> companies
      </>
    ),
    para: (
      <>
        With the experience of delivering{' '}
        <span className="color-through">924+ custom software</span> projects, we
        provide the best solutions that accelerate workflows, optimize
        operations and boost revenues
      </>
    ),
    formCTA: 'Get a Free Consultation!',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        <span className="highlight-head">Modern applications</span> to build a
        modern organization
      </>
    ),
    buildAppPara: (
      <>
        Nearly every company will have to become a technology company in order
        to compete tomorrow. Yes, even yours. But with Simform’s{' '}
        <span className="color-through">team extension service,</span> you have
        an added capacity to craft custom-built modern software born in the
        cloud.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Key <span className="highlight-head">Custom Development</span> Offerings
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Application Development Services',
        serviceParagraph: (
          <>
            Optimal business performance through <strong>top-class</strong>{' '}
            custom-built applications tailored to complement your vision.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'UX',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'System Integration Services',
        serviceParagraph: (
          <>
            Through our tech partnerships with modern platforms, we provide{' '}
            <strong>software customization</strong> and integration services.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Web',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Dashboards, ETL and Reporting Services',
        serviceParagraph: (
          <>
            Get systems <strong>“talking to each other”</strong> to make
            business more efficient by putting the right information in the
            right hands.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'API',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'Legacy Modernization & Application Migration',
        serviceParagraph: (
          <>
            We provide cloud migration services, technical architecture reviews,
            and <strong>complete application refinement</strong> to bring your
            IT up to date.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Re-engineering',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'Big Data Application Services',
        serviceParagraph: (
          <>
            Custom tools built to provide the deepest{' '}
            <strong>insights into your business</strong> using the results of
            high-volume big data processing.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'Quality',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Real-time Applications Development Services',
        serviceParagraph: (
          <>
            Harness the power of technologies like{' '}
            <strong>sensor data monitoring,</strong> stream processing, etc to
            get real-time data responses.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Integration',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Understanding your business goals',
        workingBenefitsContent: (
          <>
            Let’s discuss your short and <strong>long-term vision</strong>{' '}
            in-depth. When we know your goals and understand existing software,
            we are able to ensure the success of the project.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Well-architectured solution for scale',
        workingBenefitsContent: (
          <>
            We take a <strong>pragmatic approach</strong> to software
            architecture and design. Choosing important areas worth investing
            upfront, and prioritize others afterwards. We also provide data
            protection consultation.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Goal driven User Experience (UX) design',
        workingBenefitsContent: (
          <>
            Good software design helps <strong>drive user satisfaction.</strong>{' '}
            We perform UX research to align overall experience with your goals
            and users’ needs and let you make well-informed decisions.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Recent <span className="highlight-head">Case studies</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        Customer <span className="highlight-head">Success Stories</span>
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/cloud-cost-optimization-strategies-compute-storage/ ',
        featurecardTitle:
          'Cloud Cost Optimization Strategies (Even AWS won’t Tell you!)',
      },
      {
        blogLink: 'https://www.simform.com/serverless-architecture-guide/',
        featurecardTitle: 'Serverless Architecture: A Comprehensive Guide',
      },
      {
        blogLink: 'https://www.simform.com/mongodb-vs-mysql-databases/',
        featurecardTitle: 'MongoDB vs MySQL: A Comparative Study on Databases',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactHs,
    personImg2x: images.contactHs2x,
    personName: 'Hardik Shah',
    personDesignation:
      'Creating a tech product roadmap and building scalable apps for your organization.',
    personEmail: 'hardik@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
